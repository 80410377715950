import Avatar from '@models/Avatar/Avatar';

export default ({ $cookies, $errorHandler }, inject) => {
    inject(
        'avatar',
        new Avatar({
            $cookies,
            $errorHandler,
        })
    );
};
