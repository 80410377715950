import { v5 as uuidV5, v4 as uuidV4 } from 'uuid';
import cookies from '@services/cookies';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { CLIENT_IDENTITY_DOMAIN } from '@errors/feature-domain-names';

import { AVATAR_ID, IS_NEW_AVATAR_ID, SECONDS_IN_YEAR } from '@configs/storage';

const COOKIE_OPTIONS = {
    path: '/',
    maxAge: SECONDS_IN_YEAR,
    sameSite: 'lax',
    secure: process.env.NODE_ENV !== 'development',
};
const AVATAR_ID_COOKIE_NAME = cookies.createCookieWithPrefix(AVATAR_ID);
const IS_NEW_AVATAR_ID_COOKIE_NAME = cookies.createCookieWithPrefix(
    IS_NEW_AVATAR_ID
);
const X_INSTANCE_ID_HEADER_NAME = 'x-instance-id';
const AVATAR_UUID_NAMESPACE = '3afd7176-f7d6-5d8c-a312-e003fc3c24eb';

const createXInstanceIdHeader = avatarId => ({
    [X_INSTANCE_ID_HEADER_NAME]: avatarId,
});

export default class Avatar {
    id = null;

    constructor({ $cookies, $errorHandler }) {
        this.$cookies = $cookies;
        this.$errorHandler = $errorHandler;
        this.id = $cookies.get(AVATAR_ID_COOKIE_NAME) || null;
    }

    get isNewAvatarId() {
        return !!this.$cookies.get(IS_NEW_AVATAR_ID_COOKIE_NAME) || false;
    }

    get avatarId() {
        if (this.id) {
            return this.id;
        }

        const idFromCookies = this.avatarIdFromCookies;

        if (idFromCookies) {
            this.id = idFromCookies;
        }

        return this.id;
    }

    get avatarIdFromCookies() {
        return this.$cookies.get(AVATAR_ID_COOKIE_NAME) || null;
    }

    createAvatarId() {
        const avatarId = this.generateAvatarId();

        if (!avatarId) {
            return null;
        }

        this.id = avatarId;
        this.setAvatarIdInCookie(this.id);

        return this.id;
    }

    getInstanceIdHeader() {
        if (this.avatarId) {
            return createXInstanceIdHeader(this.avatarId);
        }

        const newAvatarId = this.createAvatarId();

        if (!newAvatarId) {
            return {};
        }

        return createXInstanceIdHeader(this.avatarId);
    }

    generateAvatarId() {
        try {
            const generatedUuid = uuidV5(uuidV4(), AVATAR_UUID_NAMESPACE);

            if (!generatedUuid) {
                this.$errorHandler.captureDomainError(
                    CLIENT_IDENTITY_DOMAIN,
                    new Error('Problem with generate uuidV5 for avatarID'),
                    {
                        [ERROR_ACTION_TAG_NAME]: 'generateAvatarId',
                    }
                );
            }

            return generatedUuid;
        } catch (err) {
            this.$errorHandler.captureDomainError(CLIENT_IDENTITY_DOMAIN, err, {
                [ERROR_ACTION_TAG_NAME]: 'generateAvatarId',
            });
        }

        return null;
    }

    setAvatarIdInCookie = avatarId => {
        if (!avatarId) {
            this.$errorHandler.captureDomainError(
                CLIENT_IDENTITY_DOMAIN,
                new Error('Missing avatarId in setAvatarIdInCookie method'),
                {
                    [ERROR_ACTION_TAG_NAME]: 'setAvatarIdInCookie',
                },
                {
                    avatarId,
                }
            );

            return;
        }

        this.$cookies.set(AVATAR_ID_COOKIE_NAME, avatarId, COOKIE_OPTIONS);
        this.$cookies.set(IS_NEW_AVATAR_ID_COOKIE_NAME, '1', COOKIE_OPTIONS);
    };

    onConnectClientSuccess() {
        this.$cookies.remove(IS_NEW_AVATAR_ID_COOKIE_NAME);
    }
}
