import asyncServicesConfig from '@async-services';

import { SYNERISE_CONFIG_NAME } from '@localeConfig/keys';

import createServices from '@services/create';

import createAsyncServices from '@modules/async-services/src/module';

import { getMegatronNormalizer } from '@normalizers/megatron-normalizers';

export default async (
    { app, store, $getLocaleConfigByKey, $config, $errorHandler, $avatar },
    inject
) => {
    const { asyncServicesManagerDebugModeEnabled } = $config;

    const {
        $abTests,
        $megatronClient,
        $httpClient,
        $cookies,
        $performanceRecorder,
    } = app;

    const config = {
        recommendations: $getLocaleConfigByKey(SYNERISE_CONFIG_NAME) || {},
    };

    const helpers = {
        abTests: $abTests,
        $cookies,
        $store: store,
        $avatar,
    };

    const normalizeMegatronResponse = getMegatronNormalizer(app, store);

    /**
     * @todo: PWA-936 - replace services with AsyncServicesManager
     */
    inject(
        'services',
        createServices(
            $errorHandler,
            $megatronClient,
            $httpClient,
            $performanceRecorder,
            config,
            helpers,
            normalizeMegatronResponse
        )
    );

    inject(
        'asyncServices',
        createAsyncServices({
            servicesConfig: asyncServicesConfig,
            runtimeServicesHelpers: {
                $errorHandler,
                megatronClient: $megatronClient,
                httpClient: $httpClient,
                config,
                $performanceRecorder,
                helpers,
                normalizeMegatronResponse,
            },
            debugModeEnabled: asyncServicesManagerDebugModeEnabled,
        })
    );
};
