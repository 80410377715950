import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_pluginstimingstart_5c03b7a6 from 'nuxt_plugin_pluginstimingstart_5c03b7a6' // Source: ../plugins/performance/plugins-timing-start.server (mode: 'server')
import nuxt_plugin_markpluginsstart_f2315868 from 'nuxt_plugin_markpluginsstart_f2315868' // Source: ../plugins/performance/mark-plugins-start.client (mode: 'client')
import nuxt_plugin_abtests_5a7f2e0e from 'nuxt_plugin_abtests_5a7f2e0e' // Source: ../plugins/ab-tests (mode: 'all')
import nuxt_plugin_sentryserver_394f2b2c from 'nuxt_plugin_sentryserver_394f2b2c' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_7d9ef8e2 from 'nuxt_plugin_sentryclient_7d9ef8e2' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginsPubSubadae5e12_4cbbbf67 from 'nuxt_plugin_pluginsPubSubadae5e12_4cbbbf67' // Source: ./plugins.PubSub.adae5e12.js (mode: 'all')
import nuxt_plugin_nuxtcorrelation_4c450d3a from 'nuxt_plugin_nuxtcorrelation_4c450d3a' // Source: ./nuxt-correlation.js (mode: 'all')
import nuxt_plugin_nuxtanalyticsclient_76f438ff from 'nuxt_plugin_nuxtanalyticsclient_76f438ff' // Source: ./nuxt-analytics.client.js (mode: 'client')
import nuxt_plugin_filterhandler_3da7fe9d from 'nuxt_plugin_filterhandler_3da7fe9d' // Source: ./filter-handler.js (mode: 'all')
import nuxt_plugin_nuxtmodals_3eaf3dac from 'nuxt_plugin_nuxtmodals_3eaf3dac' // Source: ./nuxt-modals.js (mode: 'all')
import nuxt_plugin_nuxtrumspeedcurveplugin_2e871c87 from 'nuxt_plugin_nuxtrumspeedcurveplugin_2e871c87' // Source: ./nuxt-rum-speedcurve-plugin.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_08e203ef from 'nuxt_plugin_nuxtuseragent_08e203ef' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_vuescrollto_35496ea3 from 'nuxt_plugin_vuescrollto_35496ea3' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_focusvisible_0083a9f5 from 'nuxt_plugin_focusvisible_0083a9f5' // Source: ./nuxt-polyfill/focus-visible.js (mode: 'all')
import nuxt_plugin_smoothscrollpolyfill_d5811bde from 'nuxt_plugin_smoothscrollpolyfill_d5811bde' // Source: ./nuxt-polyfill/smoothscroll-polyfill.js (mode: 'all')
import nuxt_plugin_srcpluginclient07628a4c_22d37ea5 from 'nuxt_plugin_srcpluginclient07628a4c_22d37ea5' // Source: ./src.plugin.client.07628a4c.js (mode: 'client')
import nuxt_plugin_srcpluginserver6d1bfbd4_5011be26 from 'nuxt_plugin_srcpluginserver6d1bfbd4_5011be26' // Source: ./src.plugin.server.6d1bfbd4.js (mode: 'server')
import nuxt_plugin_gtm_25512e08 from 'nuxt_plugin_gtm_25512e08' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_workbox_251d9442 from 'nuxt_plugin_workbox_251d9442' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_43477955 from 'nuxt_plugin_metaplugin_43477955' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_47ef1ec9 from 'nuxt_plugin_iconplugin_47ef1ec9' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_gtminit_29747394 from 'nuxt_plugin_gtminit_29747394' // Source: ../plugins/gtm-init (mode: 'all')
import nuxt_plugin_rumspeedcurve_bf3bd054 from 'nuxt_plugin_rumspeedcurve_bf3bd054' // Source: ../plugins/performance/rum-speedcurve.client (mode: 'client')
import nuxt_plugin_marknuxtready_7d47d618 from 'nuxt_plugin_marknuxtready_7d47d618' // Source: ../plugins/performance/mark-nuxt-ready.client (mode: 'client')
import nuxt_plugin_performancerecorder_77bf73d3 from 'nuxt_plugin_performancerecorder_77bf73d3' // Source: ../plugins/performance/performance-recorder (mode: 'all')
import nuxt_plugin_errorHandler_2ec46c3b from 'nuxt_plugin_errorHandler_2ec46c3b' // Source: ../plugins/errorHandler (mode: 'all')
import nuxt_plugin_localeconfig_7cd8c11c from 'nuxt_plugin_localeconfig_7cd8c11c' // Source: ../plugins/locale-config (mode: 'all')
import nuxt_plugin_pathhelper_d4b9f9c6 from 'nuxt_plugin_pathhelper_d4b9f9c6' // Source: ../plugins/path-helper (mode: 'all')
import nuxt_plugin_i18n_d9e26992 from 'nuxt_plugin_i18n_d9e26992' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_imaginator_2938a71e from 'nuxt_plugin_imaginator_2938a71e' // Source: ../plugins/imaginator (mode: 'all')
import nuxt_plugin_cookies_7600aed6 from 'nuxt_plugin_cookies_7600aed6' // Source: ../plugins/cookies (mode: 'all')
import nuxt_plugin_avatar_00c13032 from 'nuxt_plugin_avatar_00c13032' // Source: ../plugins/avatar (mode: 'all')
import nuxt_plugin_mobileapp_0dc68e62 from 'nuxt_plugin_mobileapp_0dc68e62' // Source: ../plugins/mobile-app (mode: 'all')
import nuxt_plugin_megatronclient_175f2760 from 'nuxt_plugin_megatronclient_175f2760' // Source: ../plugins/megatron-client (mode: 'all')
import nuxt_plugin_httpclient_46d31d77 from 'nuxt_plugin_httpclient_46d31d77' // Source: ../plugins/http-client (mode: 'all')
import nuxt_plugin_servicesinject_fcca99b2 from 'nuxt_plugin_servicesinject_fcca99b2' // Source: ../plugins/services-inject (mode: 'all')
import nuxt_plugin_themevariables_102223ba from 'nuxt_plugin_themevariables_102223ba' // Source: ../plugins/theme-variables (mode: 'all')
import nuxt_plugin_validation_5ee4f332 from 'nuxt_plugin_validation_5ee4f332' // Source: ../plugins/validation.client (mode: 'client')
import nuxt_plugin_storage_e473a712 from 'nuxt_plugin_storage_e473a712' // Source: ../plugins/storage.client (mode: 'client')
import nuxt_plugin_recentlyViewedProducts_35fec9b0 from 'nuxt_plugin_recentlyViewedProducts_35fec9b0' // Source: ../plugins/recentlyViewedProducts.client (mode: 'client')
import nuxt_plugin_localWishlist_a6e4ff7c from 'nuxt_plugin_localWishlist_a6e4ff7c' // Source: ../plugins/localWishlist.client (mode: 'client')
import nuxt_plugin_googleAuth_ed8c2b42 from 'nuxt_plugin_googleAuth_ed8c2b42' // Source: ../plugins/googleAuth.client (mode: 'client')
import nuxt_plugin_facebook_04e5a93a from 'nuxt_plugin_facebook_04e5a93a' // Source: ../plugins/facebook.client (mode: 'client')
import nuxt_plugin_intersectionobserverpolyfill_5695c31e from 'nuxt_plugin_intersectionobserverpolyfill_5695c31e' // Source: ../plugins/intersection-observer-polyfill.client (mode: 'client')
import nuxt_plugin_pictureElement_4a7f5fa2 from 'nuxt_plugin_pictureElement_4a7f5fa2' // Source: ../plugins/pictureElement.client (mode: 'client')
import nuxt_plugin_recaptchav3_2bf5c0bd from 'nuxt_plugin_recaptchav3_2bf5c0bd' // Source: ../plugins/recaptcha-v3.client (mode: 'client')
import nuxt_plugin_ismobilewatcher_064822fe from 'nuxt_plugin_ismobilewatcher_064822fe' // Source: ../plugins/is-mobile-watcher.client (mode: 'client')
import nuxt_plugin_istabletordesktopwatcher_7032eeca from 'nuxt_plugin_istabletordesktopwatcher_7032eeca' // Source: ../plugins/is-tablet-or-desktop-watcher.client (mode: 'client')
import nuxt_plugin_webpushnotificationsrequestpermissionclient_314e2611 from 'nuxt_plugin_webpushnotificationsrequestpermissionclient_314e2611' // Source: ../plugins/web-push-notifications-request-permission.client.js (mode: 'client')
import nuxt_plugin_fetchinparallel_db2722ba from 'nuxt_plugin_fetchinparallel_db2722ba' // Source: ../plugins/fetch-in-parallel (mode: 'all')
import nuxt_plugin_nuxtclientinitclient_3e4c7382 from 'nuxt_plugin_nuxtclientinitclient_3e4c7382' // Source: ../plugins/nuxt-client-init.client.js (mode: 'client')
import nuxt_plugin_storetiming_6cbee342 from 'nuxt_plugin_storetiming_6cbee342' // Source: ../plugins/store-timing.server (mode: 'server')
import nuxt_plugin_register_66bbec80 from 'nuxt_plugin_register_66bbec80' // Source: ../modules/rma/src/async-services/register (mode: 'all')
import nuxt_plugin_register_704f4011 from 'nuxt_plugin_register_704f4011' // Source: ../modules/rma/src/i18n/register (mode: 'all')
import nuxt_plugin_plugin_77386af4 from 'nuxt_plugin_plugin_77386af4' // Source: ../modules/rma/src/errors/plugin (mode: 'all')
import nuxt_plugin_register_11da42e0 from 'nuxt_plugin_register_11da42e0' // Source: ../modules/search/src/async-services/register (mode: 'all')
import nuxt_plugin_register_6d76bc26 from 'nuxt_plugin_register_6d76bc26' // Source: ../modules/loyalty-club/src/i18n/register (mode: 'all')
import nuxt_plugin_register_5c7b1255 from 'nuxt_plugin_register_5c7b1255' // Source: ../modules/loyalty-club/src/async-services/register (mode: 'all')
import nuxt_plugin_markpluginsend_ed98031a from 'nuxt_plugin_markpluginsend_ed98031a' // Source: ../plugins/performance/mark-plugins-end.client (mode: 'client')
import nuxt_plugin_pluginstimingend_17ccf514 from 'nuxt_plugin_pluginstimingend_17ccf514' // Source: ../plugins/performance/plugins-timing-end.server (mode: 'server')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"hid":"referrer","name":"referrer","content":"no-referrer-when-downgrade"},{"hid":"application-name","name":"application-name","content":"eobuwie"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"eobuwie"},{"hid":"og:site_name","property":"og:site_name","content":"eobuwie"},{"hid":"og:locale","property":"og:locale","content":"pl_PL"},{"hid":"og:type","property":"og:type","content":"website"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_pluginstimingstart_5c03b7a6 === 'function') {
    await nuxt_plugin_pluginstimingstart_5c03b7a6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_markpluginsstart_f2315868 === 'function') {
    await nuxt_plugin_markpluginsstart_f2315868(app.context, inject)
  }

  if (typeof nuxt_plugin_abtests_5a7f2e0e === 'function') {
    await nuxt_plugin_abtests_5a7f2e0e(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_394f2b2c === 'function') {
    await nuxt_plugin_sentryserver_394f2b2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_7d9ef8e2 === 'function') {
    await nuxt_plugin_sentryclient_7d9ef8e2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsPubSubadae5e12_4cbbbf67 === 'function') {
    await nuxt_plugin_pluginsPubSubadae5e12_4cbbbf67(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtcorrelation_4c450d3a === 'function') {
    await nuxt_plugin_nuxtcorrelation_4c450d3a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtanalyticsclient_76f438ff === 'function') {
    await nuxt_plugin_nuxtanalyticsclient_76f438ff(app.context, inject)
  }

  if (typeof nuxt_plugin_filterhandler_3da7fe9d === 'function') {
    await nuxt_plugin_filterhandler_3da7fe9d(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmodals_3eaf3dac === 'function') {
    await nuxt_plugin_nuxtmodals_3eaf3dac(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtrumspeedcurveplugin_2e871c87 === 'function') {
    await nuxt_plugin_nuxtrumspeedcurveplugin_2e871c87(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_08e203ef === 'function') {
    await nuxt_plugin_nuxtuseragent_08e203ef(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_35496ea3 === 'function') {
    await nuxt_plugin_vuescrollto_35496ea3(app.context, inject)
  }

  if (typeof nuxt_plugin_focusvisible_0083a9f5 === 'function') {
    await nuxt_plugin_focusvisible_0083a9f5(app.context, inject)
  }

  if (typeof nuxt_plugin_smoothscrollpolyfill_d5811bde === 'function') {
    await nuxt_plugin_smoothscrollpolyfill_d5811bde(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_srcpluginclient07628a4c_22d37ea5 === 'function') {
    await nuxt_plugin_srcpluginclient07628a4c_22d37ea5(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_srcpluginserver6d1bfbd4_5011be26 === 'function') {
    await nuxt_plugin_srcpluginserver6d1bfbd4_5011be26(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_25512e08 === 'function') {
    await nuxt_plugin_gtm_25512e08(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_251d9442 === 'function') {
    await nuxt_plugin_workbox_251d9442(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_43477955 === 'function') {
    await nuxt_plugin_metaplugin_43477955(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_47ef1ec9 === 'function') {
    await nuxt_plugin_iconplugin_47ef1ec9(app.context, inject)
  }

  if (typeof nuxt_plugin_gtminit_29747394 === 'function') {
    await nuxt_plugin_gtminit_29747394(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_rumspeedcurve_bf3bd054 === 'function') {
    await nuxt_plugin_rumspeedcurve_bf3bd054(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_marknuxtready_7d47d618 === 'function') {
    await nuxt_plugin_marknuxtready_7d47d618(app.context, inject)
  }

  if (typeof nuxt_plugin_performancerecorder_77bf73d3 === 'function') {
    await nuxt_plugin_performancerecorder_77bf73d3(app.context, inject)
  }

  if (typeof nuxt_plugin_errorHandler_2ec46c3b === 'function') {
    await nuxt_plugin_errorHandler_2ec46c3b(app.context, inject)
  }

  if (typeof nuxt_plugin_localeconfig_7cd8c11c === 'function') {
    await nuxt_plugin_localeconfig_7cd8c11c(app.context, inject)
  }

  if (typeof nuxt_plugin_pathhelper_d4b9f9c6 === 'function') {
    await nuxt_plugin_pathhelper_d4b9f9c6(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_d9e26992 === 'function') {
    await nuxt_plugin_i18n_d9e26992(app.context, inject)
  }

  if (typeof nuxt_plugin_imaginator_2938a71e === 'function') {
    await nuxt_plugin_imaginator_2938a71e(app.context, inject)
  }

  if (typeof nuxt_plugin_cookies_7600aed6 === 'function') {
    await nuxt_plugin_cookies_7600aed6(app.context, inject)
  }

  if (typeof nuxt_plugin_avatar_00c13032 === 'function') {
    await nuxt_plugin_avatar_00c13032(app.context, inject)
  }

  if (typeof nuxt_plugin_mobileapp_0dc68e62 === 'function') {
    await nuxt_plugin_mobileapp_0dc68e62(app.context, inject)
  }

  if (typeof nuxt_plugin_megatronclient_175f2760 === 'function') {
    await nuxt_plugin_megatronclient_175f2760(app.context, inject)
  }

  if (typeof nuxt_plugin_httpclient_46d31d77 === 'function') {
    await nuxt_plugin_httpclient_46d31d77(app.context, inject)
  }

  if (typeof nuxt_plugin_servicesinject_fcca99b2 === 'function') {
    await nuxt_plugin_servicesinject_fcca99b2(app.context, inject)
  }

  if (typeof nuxt_plugin_themevariables_102223ba === 'function') {
    await nuxt_plugin_themevariables_102223ba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_validation_5ee4f332 === 'function') {
    await nuxt_plugin_validation_5ee4f332(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_storage_e473a712 === 'function') {
    await nuxt_plugin_storage_e473a712(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_recentlyViewedProducts_35fec9b0 === 'function') {
    await nuxt_plugin_recentlyViewedProducts_35fec9b0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localWishlist_a6e4ff7c === 'function') {
    await nuxt_plugin_localWishlist_a6e4ff7c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleAuth_ed8c2b42 === 'function') {
    await nuxt_plugin_googleAuth_ed8c2b42(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebook_04e5a93a === 'function') {
    await nuxt_plugin_facebook_04e5a93a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_intersectionobserverpolyfill_5695c31e === 'function') {
    await nuxt_plugin_intersectionobserverpolyfill_5695c31e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pictureElement_4a7f5fa2 === 'function') {
    await nuxt_plugin_pictureElement_4a7f5fa2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_recaptchav3_2bf5c0bd === 'function') {
    await nuxt_plugin_recaptchav3_2bf5c0bd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ismobilewatcher_064822fe === 'function') {
    await nuxt_plugin_ismobilewatcher_064822fe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_istabletordesktopwatcher_7032eeca === 'function') {
    await nuxt_plugin_istabletordesktopwatcher_7032eeca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webpushnotificationsrequestpermissionclient_314e2611 === 'function') {
    await nuxt_plugin_webpushnotificationsrequestpermissionclient_314e2611(app.context, inject)
  }

  if (typeof nuxt_plugin_fetchinparallel_db2722ba === 'function') {
    await nuxt_plugin_fetchinparallel_db2722ba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtclientinitclient_3e4c7382 === 'function') {
    await nuxt_plugin_nuxtclientinitclient_3e4c7382(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storetiming_6cbee342 === 'function') {
    await nuxt_plugin_storetiming_6cbee342(app.context, inject)
  }

  if (typeof nuxt_plugin_register_66bbec80 === 'function') {
    await nuxt_plugin_register_66bbec80(app.context, inject)
  }

  if (typeof nuxt_plugin_register_704f4011 === 'function') {
    await nuxt_plugin_register_704f4011(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_77386af4 === 'function') {
    await nuxt_plugin_plugin_77386af4(app.context, inject)
  }

  if (typeof nuxt_plugin_register_11da42e0 === 'function') {
    await nuxt_plugin_register_11da42e0(app.context, inject)
  }

  if (typeof nuxt_plugin_register_6d76bc26 === 'function') {
    await nuxt_plugin_register_6d76bc26(app.context, inject)
  }

  if (typeof nuxt_plugin_register_5c7b1255 === 'function') {
    await nuxt_plugin_register_5c7b1255(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_markpluginsend_ed98031a === 'function') {
    await nuxt_plugin_markpluginsend_ed98031a(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginstimingend_17ccf514 === 'function') {
    await nuxt_plugin_pluginstimingend_17ccf514(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
