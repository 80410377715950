import { HOT_DEAL_ATTRIBUTE_NAME } from '@configs/product-attribute-names';

import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';

import DateHelper from '@models/DateHelper/DateHelper';
import { hotDealAttributeModel } from '@models/Catalog/attributes';

import { getNormalizedAttributeModelValues } from '@assets/product-attributes';

import { light } from '@theme/resources/variables/colors.json';

const boldRegExp = /\*\*(.*?)\*\*/gm;
const tagsRegExp = /(<([^>]+)>)/gi;

const WEB_DISTRIBUTION = 'web';

const isWebChannel = ({ kanal_dystrybucji: channel }) => {
    if (!Array.isArray(channel)) {
        return false;
    }

    return channel.some(item => item?.code === WEB_DISTRIBUTION);
};
const actionGeneralConditions = action =>
    !!action?.wlepka_kolor && isWebChannel(action);

const actionListingConditions = (action, locale) =>
    !!action.wlepka_tresc && !!action.wlepka_tresc[locale];

const actionProductCardConditions = (action, locale) =>
    !!action.tresc_karta_produktu && !!action.tresc_karta_produktu[locale];

const isActive = (storeViewTimezone, startDate, endDate) => {
    const dateHelper = new DateHelper(storeViewTimezone, FORMAT_YYYYMMDD_HHMM);

    const actionStart = dateHelper.createDateFromStringInTimezone(startDate);

    const actionEnd = dateHelper.createDateFromStringInTimezone(endDate);

    const currentDeviceTimeInStoreTimezone = dateHelper.createCurrentDateInTimezone();

    return DateHelper.isCurrentDateBetweenPassed(
        actionStart,
        actionEnd,
        currentDeviceTimeInStoreTimezone
    );
};

const timeToEnd = (storeViewTimezone, endDate) => {
    const dateHelper = new DateHelper(storeViewTimezone, FORMAT_YYYYMMDD_HHMM);

    const actionEnd = dateHelper.createDateFromStringInTimezone(endDate);

    const currentDeviceTimeInStoreTimezone = dateHelper.createCurrentDateInTimezone();

    return DateHelper.timeDiffCurrentToEnd(
        actionEnd,
        currentDeviceTimeInStoreTimezone
    );
};

export const getValidPromoSticker = ({
    sticker,
    sku,
    locale,
    storeViewTimezone,
}) => {
    try {
        const actions = Object.values(sticker);

        const currentlyActiveActions = actions.filter(({ values: action }) => {
            const startDate = action?.start_date;
            const endDate = action?.end_date;

            if (!startDate || !endDate) {
                return false;
            }

            return isActive(storeViewTimezone, startDate, endDate);
        });

        let actionsWithValues = currentlyActiveActions.filter(
            ({ values: action }) =>
                actionGeneralConditions(action) &&
                actionListingConditions(action, locale) &&
                actionProductCardConditions(action, locale)
        );

        if (!actionsWithValues.length) {
            actionsWithValues = currentlyActiveActions.filter(
                ({ values: action }) =>
                    actionGeneralConditions(action) &&
                    (actionListingConditions(action, locale) ||
                        actionProductCardConditions(action, locale))
            );

            if (!actionsWithValues.length) {
                return null;
            }
        }

        const [firstValidAction] = actionsWithValues;

        const { values: actionValues } = firstValidAction;

        const {
            wlepka_tresc: { [locale]: content = '' } = {},
            tresc_karta_produktu: { [locale]: productCardContent = '' },
            action_code: { [locale]: code },
            link_url: { [locale]: linkUrl },
            link_text: { [locale]: linkText },
            show_timer: { [locale]: showTimer },
            wlepka_kolor: bgColor,
            start_date: startDate,
            end_date: endDate,
            is_hot_deal: isHotDeal = false,
            pokazuj_date: showDate,
        } = {
            wlepka_tresc: {},
            tresc_karta_produktu: {},
            action_code: {},
            link_url: {},
            link_text: {},
            show_timer: {},
            wlepka_kolor: null,
            start_date: null,
            end_date: null,
            pokazuj_date: true,
            ...actionValues,
        };

        const isTimerEnabled = showTimer?.code === 'tak';

        return {
            content,
            productCardContent,
            formattedContent: content
                .replace(tagsRegExp, '')
                .replace(boldRegExp, '<strong>$1</strong>'),
            formattedProductCardContent: productCardContent
                .replace(tagsRegExp, '')
                .replace(boldRegExp, '<strong>$1</strong>'),
            bgColor,
            textColor: light,
            startDate,
            endDate,
            showDate,
            code,
            linkUrl,
            linkText,
            isTimerEnabled,
            isHotDeal,
            timeToEnd: timeToEnd(storeViewTimezone, endDate),
        };
    } catch (err) {
        console.error(
            `[Sticker] An error occured while fetching promoSticker for sku: ${sku}`,
            err
        );

        return null;
    }
};

export const getHotDealPromoSticker = ({
    hotDealAttributeValue,
    locale,
    storeViewTimezone,
}) => {
    const { values: attributeValues } = hotDealAttributeValue;

    if (!attributeValues || !storeViewTimezone) {
        return null;
    }

    const dateHelper = new DateHelper(storeViewTimezone, FORMAT_YYYYMMDD_HHMM);

    const hotDeal = getNormalizedAttributeModelValues(
        hotDealAttributeModel,
        attributeValues,
        { dateHelper },
        value => value?.[locale]
    );

    const hasAllRequiredValues = Object.entries(hotDealAttributeModel).every(
        ([key, { isOptional }]) => isOptional || hotDeal[key] !== null
    );

    if (!hasAllRequiredValues) {
        return null;
    }

    const now = dateHelper.createCurrentDateInTimezone();

    const { start_date, end_date } = hotDeal;

    hotDeal.is_active =
        start_date &&
        end_date &&
        DateHelper.isCurrentDateBetweenPassed(start_date, end_date, now);

    if (!hotDeal.is_active) {
        return null;
    }

    hotDeal.start_date = DateHelper.toISO(start_date);
    hotDeal.end_date = DateHelper.toISO(end_date);

    const {
        label_text: labelText,
        label_color: labelColor,
        kp_label_text: kpLabelText,
        start_date_raw: startDate,
        end_date_raw: endDate,
        action_code: actionCode,
        link_url: linkUrl,
        link_text: linkText,
        show_timer: showTimer,
    } = hotDeal;

    return {
        code: HOT_DEAL_ATTRIBUTE_NAME,
        values: {
            kanal_dystrybucji: [{ code: WEB_DISTRIBUTION }],
            wlepka_tresc: { [locale]: labelText },
            wlepka_kolor: labelColor,
            start_date: startDate,
            end_date: endDate,
            tresc_karta_produktu: { [locale]: kpLabelText },
            action_code: { [locale]: actionCode },
            link_url: { [locale]: linkUrl },
            link_text: { [locale]: linkText },
            show_timer: { [locale]: showTimer },
            is_hot_deal: true,
        },
    };
};
