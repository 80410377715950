export const MEGATRON_SEARCH_GET_AUTOCOMPLETE_OPERATION_NAME =
    'getAutocomplete';
export const MEGATRON_SEARCH_GET_SEARCH_OPERATION_NAME = 'getSearch';
export const MEGATRON_SEARCH_GET_PRODUCT_OPERATION_NAME = 'getProduct';
export const MEGATRON_SEARCH_GET_CATALOG_PRODUCTS_OPERATION_NAME =
    'getCatalogProducts';
export const MEGATRON_SEARCH_GET_SPONSORED_PRODUCTS_OPERATION_NAME =
    'getSponsoredProducts';
export const MEGATRON_SEARCH_GET_PRODUCTS_OPERATION_NAME = 'getProducts';
export const MEGATRON_SEARCH_GET_FILTERS_OPERATION_NAME = 'getFilters';
export const MEGATRON_SEARCH_GET_CATEGORIES_OPERATION_NAME = 'getCategories';
export const MEGATRON_SEARCH_GET_CATALOG_COLOR_VARIANTS_OPERATION_NAME =
    'getCatalogColorVariants';

export const MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO = 'magento';
export const MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO_REST = 'magento-rest';
export const MEGATRON_CLIENT_ENDPOINT_NAME_SYNERISE = 'synerise';
export const MEGATRON_CLIENT_ENDPOINT_NAME_OMNIBUS = 'omnibus';
export const MEGATRON_CLIENT_ENDPOINT_NAME_CHECKOUT = 'checkout';
export const MEGATRON_CLIENT_ENDPOINT_NAME_PACKAGE_TRACKING =
    'package-tracking';
export const MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH = 'search';
export const MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH_GCP = 'search-gcp';
export const MEGATRON_CLIENT_ENDPOINT_NAME_SMAUG = 'smaug-api';
export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO_REST = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO_REST,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SYNERISE = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_SYNERISE,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_OMNIBUS = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_OMNIBUS,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_PACKAGE_TRACKING = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_PACKAGE_TRACKING,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH_OVH = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SEARCH_GCP = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH_GCP,
};

export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_CHECKOUT = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_CHECKOUT,
};
export const MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG = {
    endpoint: MEGATRON_CLIENT_ENDPOINT_NAME_SMAUG,
};
